import React, { useContext, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Likes from '../likes';
import Auctions_dropdown from '../dropdown/Auctions_dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { buyModalShow } from '../../redux/counterSlice';
import { urlFor } from '../../sanity';
import TokenData from '../../data/token_data';
import Countdown_timer from '../Countdown_timer';
import { ClubBaseContext } from '../../contexts/ClubBaseProvider';
import { useRouter } from 'next/router';
import EmptyCategoryItem from './emptyCategoryItem';
import { MetaplexContext } from '../../contexts/MetaplexProvider';
import { dataURLtoFile, getMetadataToken, routeCheckSoloApp, toDataURL } from '../../utils/generalUtils';
import Raffle_item_dropdown from '../dropdown/Raffle_item_dropdown';
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from 'react-query'
import { getRafflesBatch } from '../../libs/sanityHelper';
import Loader from '../other/Loader';

const RaffleCategoryItem = () => {
	const {allRaffles} = useContext(ClubBaseContext)
	const { raffleLiveDataList, raffleFinishedDataList, filterType } = useSelector((state) => state.counter);
//console.log(sortedtrendingCategoryItemData)
	const [newRaffleDataList, setNewRaffleDataList] = useState(raffleLiveDataList)
	const [newRaffleDataListFinished, setNewRaffleDataListFinished] = useState(raffleFinishedDataList)
	const [parsedData, setParsedData] = useState(null)
	const [nonSyncedDataList, setNonSyncedDataList] = useState([])
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0)
	const [synced, setSynced] = useState(false)
	const dispatch = useDispatch();
	const router = useRouter()

	const { projectData } = useSelector((state) => state.counter);
	// useEffect(() => {
	// 	setParsedData(sortedtrendingCategoryItemData)
	// }, [sortedtrendingCategoryItemData])
	const getRaffles = async () => { 
		const isLiveRaffles = router.query.type === "finished" ? false : true
		console.log("react query")
		const raffles = await getRafflesBatch(projectData._id, isLiveRaffles, (8*(page+1)), (8*(page+1)*2)-1)
		if(raffles.length > 0){
			const liveRaffles = raffles.filter(x => x.islive)
			const finishedRaffles = raffles.filter(x => !x.islive)
			setPage(page + 1)
			setNewRaffleDataList([...newRaffleDataList, ...liveRaffles])
			setNewRaffleDataListFinished([...newRaffleDataListFinished, ...finishedRaffles])
			if(raffles.length < 8){
				setHasMore(false)
			}
		}else{
			setHasMore(false)
		}
	}

	const { data, refetch } = useQuery("rafflesList", getRaffles, {
		refetchOnWindowFocus: false,
		enabled: false // disable this query from automatically running
	  });


	  useEffect(() => {
		setPage(0)
		setHasMore(true)
	  }, [router.query.type])
	  
	

	const getMoreItems = () => { 
		console.log("Getting more items...", projectData)
		refetch()
	 }
	 

	 useEffect(() => {
	   if(raffleLiveDataList){
		setNewRaffleDataList(raffleLiveDataList)
	   }
	 }, [raffleLiveDataList])
	 

	 useEffect(() => {
		if(raffleFinishedDataList){
			setNewRaffleDataListFinished(raffleFinishedDataList)
		}
	  }, [raffleFinishedDataList])


	useMemo(() => {
		console.log("DATA 2", allRaffles, newRaffleDataList)
		console.log(newRaffleDataList.map(x => x.itemName))
		if((allRaffles && (newRaffleDataList.length > 0 || newRaffleDataListFinished.length > 0))){
			let syncdDatalistLive = []
			let syncdDatalistfinished = []
			let nonSyncDatalist = []
			let onchainraffleslive = allRaffles.filter(x => x.live === 1 && (x.endTs.toNumber() * 1000 > new Date().getTime()))
			console.log(onchainraffleslive)
			for (let i = 0; i < newRaffleDataList?.length; i++) {
				
				const index = onchainraffleslive.findIndex(x => x.accountKey.toString() === newRaffleDataList[i].id)

				if(index > -1){
					console.log("sanity data found")
					if(!newRaffleDataList[i].refresh){
						syncdDatalistLive.push({
							...newRaffleDataList[i],
							totalEntries: onchainraffleslive[index].totalEntries,
							refresh: true,
							onchainData: onchainraffleslive[index]
						})

						onchainraffleslive.splice(index, 1)
					}
				}
			}
			let onchainrafflesfinished = allRaffles.filter(x => (x.endTs.toNumber() * 1000 <= new Date().getTime()||!x.live))
			for (let i = 0; i < newRaffleDataListFinished?.length; i++) {
				
				const index = onchainrafflesfinished.findIndex(x => x.accountKey.toString() === newRaffleDataListFinished[i].id)

				if(index > -1){
					console.log("sanity data found")
					if(!newRaffleDataListFinished[i].refresh){
						syncdDatalistfinished.push({
							...newRaffleDataListFinished[i],
							totalEntries: onchainrafflesfinished[index].totalEntries,
							refresh: true,
							onchainData: onchainrafflesfinished[index]
						})

						onchainrafflesfinished.splice(index, 1)
					}
				}
			}

			// console.log(onchainraffleslive)
			// console.log(syncdDatalist)
			// for (const raffle of allRaffles) {

			// 	const index = raffleLiveDataList.findIndex(x => x.id === raffle.accountKey.toString())


			// 	if(index > -1){
			// 		if(!raffleLiveDataList[index].refresh){
			// 			syncdDatalist.push({
			// 				...raffleLiveDataList[index],
			// 				totalEntries: raffle.totalEntries,
			// 				refresh: true,
			// 				onchainData: raffle
			// 			})
			// 		}
			// 	}else{
			// 		if(raffle.live === 1 && (raffle.endTs.toNumber() * 1000 > new Date().getTime()) ){
			// 			nonSyncDatalist.push(raffle)
			// 		}
			// 	}

			// }

			console.log("syncdDatalist",syncdDatalistLive, syncdDatalistfinished)
			console.log("syncdDatalist",syncdDatalistLive, syncdDatalistfinished)
			//console.log("nonSyncDatalist", nonSyncDatalist)
			setParsedData([...syncdDatalistLive, ...syncdDatalistfinished])
			//setNonSyncedDataList([])
			// if(raffleType === 'all'){
			// 	setNonSyncedDataList([...onchainraffleslive])
			// }else{
				setNonSyncedDataList([])
		}else{
			console.log("Else condition")
			setParsedData(null)
			//setParsedData(raffleLiveDataList)
		}
	}, [allRaffles, newRaffleDataList, newRaffleDataListFinished])
	

	 useEffect(() => {

		;(async()=>{
			let nonSyncList = nonSyncedDataList
			let updatedSyncList = nonSyncedDataList
			console.log("non sync found 1", nonSyncList)
			if(nonSyncList.length > 0 && !synced){
				console.log("non sync found", nonSyncList)
				let index = 0;
				for (const item of nonSyncList) {
					if(item && item.isOnChain){
						const tokenData = await getMetadataToken(item.itemMint.toString())
						if (tokenData && tokenData.json && tokenData.json.image) {
							const dataUrl = await toDataURL(tokenData.json.image)
								var image = dataURLtoFile(dataUrl, `${item.itemMint.toString()}.png`);
								var token = TokenData.find(x => x.mintKey === item.raffleTokenMint.toString())
	
								let collectionkey = ''
								if (tokenData.collection) {
									collectionkey = tokenData.collection.address.toString()
								  } else if (tokenData.creators) {
									collectionkey = tokenData.creators[0].address.toString()
								}
						
	
								console.log("collectionkey",collectionkey)
								let raffleData = {
									account: item.accountKey.toString(),
									raffleType: item.raffleType - 1,
									isOnChain: item.isOnChain === 1,
									itemMint: item.itemMint.toString(),
									itemName: tokenData.json.name,
									itemDescritpion: tokenData.json.description,
									itemImage: image,
									totalWinners: item.winners,
									maxEntries: item.maxEntries, 
									amountPerEntry: item.numTokenPerEntry.toNumber()/token.lamportsPerToken,
									tokenKey: item.raffleTokenMint.toString(),
									tokenLamports: token.lamportsPerToken,
									raffleStartTime: item.startTs.toNumber()*1000,
									raffleEndTime: item.endTs.toNumber()*1000,
									collectionkey: collectionkey,
									project: projectData._id
								}
	
								const formData = new FormData();
								formData.append("image", image)
							
								Object.keys(raffleData).forEach(key => {
								  formData.append(key, raffleData[key]);
								});
	
								const response = await fetch("/api/createRaffle", {
									method: "POST",
									headers: {
									  Accept: "application/json",
									},
									body: formData,
								  });
							  
								const json = await response.json();
								if(json.success){
									const todayTs = new Date().getTime()
									parsedData.push({
										...json.data,
										totalEntries: item.totalEntries,
										refresh: true,
										onchainData: item,
										islive: todayTs < item.endTs.toNumber()*1000,
										timeLeft: item.endTs.toNumber()*1000 - todayTs
									})
									setParsedData([...parsedData])
									updatedSyncList.splice(index, 1)
								}
						}
						
					}
					index++
				}
				setNonSyncedDataList([])
				// if(updatedSyncList.length !== nonSyncedDataList){
				// 	setNonSyncedDataList([...updatedSyncList])
				// }
				setSynced(true)
				
		    }
		})()
	 }, [nonSyncedDataList])
	 

	 const goToRaffle = (slug) => { 
		router.push(routeCheckSoloApp(router.query.projectId, `raffles/${slug}`))
	 }

	 const chainBadge = (isonchain) => (
		<Tippy className='p-2' content={isonchain ? "On-Chain Reward":"Off-Chain Item"}>
		<div className='btn btn-circle btn-sm text-primary'>
		<svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 h-4 w-4">
											<use xlinkHref={`/icons.svg#icon-${isonchain ? "onchain":"offchain"}`}></use>
										</svg>
										</div></Tippy>
	 )

	 const typeBadge = (type) => (
		<Tippy className='p-2' content={type == "nft" ? "NFT item": type == "nft" ? "SFT/WL Token" : "Physical Item"}>
		<div className='btn btn-circle btn-sm text-accent'>
		<svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 h-4 w-4">
											<use xlinkHref={`/icons.svg#icon-${type}`}></use>
										</svg>
										</div></Tippy>
	 )

	 const winnersBadge = (number) => (
		<Tippy className='p-2' content={`${number} ${number > 1 ? " Winners" : " Winner"}`}>
		<div className='btn btn-circle btn-sm text-black'>
		<p>{number}</p>
										</div></Tippy>
	 )


	return (
		<>
			{parsedData ? <InfiniteScroll
			className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4"
        dataLength={parsedData.length}
        next={getMoreItems}
        hasMore={hasMore}
        loader={<div className='w-full flex items-center justify-start px-4'><Loader/><p className='text-lg'>Loading more...</p></div>}
        endMessage={<div className='w-full flex items-center justify-start px-4'><p className='text-lg'>🥳 You are all caught up!</p></div>}
      >
			{parsedData?.map((item) => {
				const { refresh, _id, islive, raffleType, itemImage, itemName, amountPerEntry, tokenKey, raffleSlug, collection, timeLeft, totalEntries, maxEntries, isOnChain, totalWinners, onchainData } = item;
				const imageLink = urlFor(itemImage).url()
				return (
					<article key={_id}>
						<div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
							
							<figure className={`relative w-full h-[285px] rounded-[0.625rem] object-cover cursor-pointer overflow-hidden`}>
										<Image 
											onClick={()=>goToRaffle(raffleSlug.current)}
											src={imageLink}
											layout="fill"
											objectFit='cover'
											className={`${(!islive || onchainData?.live === 0) && "grayscale"} `}
										/>
								{totalEntries < maxEntries ? <Countdown_timer time={+timeLeft} />:
								<div className="text-2xs absolute bottom-4 left-1/2 flex -translate-x-1/2 items-center justify-center space-x-1 rounded-full bg-white py-2.5 px-6 font-medium">
      <span
        className="js-countdown-timer text-jacarta-700 shrink-0 whitespace-nowrap"
        data-countdown="2022-05-14T10:45:30"
        data-expired="This auction has ended"
      >
        Raffle Sold Out!
      </span>
    </div>}

								{/* <Likes like={likes} /> */}

								<div className="absolute right-3 top-3">
								<div className='flex items-center gap-1'>{winnersBadge(totalWinners)}{chainBadge(isOnChain)}{typeBadge(raffleType)}</div>
								
							
									{/* <div className="flex -space-x-2">
										<Link href={`/item/${itemLink}`}>
											<a>
												<Tippy content={<span>creator: {creator.name}</span>}>
													<img
														src={creator.image}
														alt="creator"
														className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
													/>
												</Tippy>
											</a>
										</Link>
										<Link href={`/item/${itemLink}`}>
											<a>
												<Tippy content={<span>creator: {owner.name}</span>}>
													<img
														src={owner.image}
														alt="owner"
														layout="fill"
														className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
													/>
												</Tippy>
											</a>
										</Link>
									</div> */}
								</div>
							</figure>
		
							<div className="relative mt-4 mb-4 flex items-center justify-between">
								<div className='flex flex-col items-start'>
								<Link href={routeCheckSoloApp(router.asPath, `raffles/${raffleSlug.current}`)}>
									<a>
										<span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
											{itemName}
										</span>
									</a>
								</Link>
								{collection ? <p className='flex items-center gap-1 text-xs text-accent-light'>
									<span>{collection.collectionName}</span> <svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 mr-1 mb-[3px] h-4 w-4">
											<use xlinkHref="/icons.svg#icon-shield"></use>
										</svg>
								</p>: <p className='flex items-center gap-1 text-xs text-accent-light'>
									<span>Unknown Collection</span>
								</p>}
								</div>
								{/* auction dropdown  */}
								<Raffle_item_dropdown classes="dark:hover:bg-jacarta-600 dropup hover:bg-jacarta-100 rounded-full" />
							</div>
							<div className="flex items-center justify-between mt-2 text-sm">
								<div>
								🎟
								<span className="dark:text-jacarta-200 text-jacarta-700 ml-1 mr-1">{amountPerEntry}</span>
								<span className="dark:text-jacarta-300 text-jacarta-500">
									{TokenData.find(x => x.mintKey === tokenKey).symbol}
								</span>
								</div>
								<div className='flex items-center gap-1'>
									{!refresh && <div className='animate-pulse w-8 h-4 rounded bg-jacarta-100'></div>} <span>{refresh && totalEntries} / {maxEntries}</span>
								</div>
							</div>

							{refresh ? <div className="w-full bg-jacarta-100 rounded-full h-2">
  <div className="bg-accent h-2 rounded-full" style={{width:`${parseInt((totalEntries/maxEntries)*100)}%`}}></div>
</div>
:
<div className="animate-pulse w-full bg-jacarta-100 rounded-full h-2"></div>}

							<div className="mt-2 flex items-center justify-between">
								<button
									className={`${onchainData?.live === 1 && islive ? "text-accent" : "text-jacarta-400"} font-display text-sm font-semibold`}
									onClick={() => dispatch(buyModalShow())}
								>
									{onchainData?.live === 1 && islive ? "LIVE" : "ENDED"}
								</button>
								<Link href={routeCheckSoloApp(router.asPath, `raffles/${raffleSlug.current}`)}>
									<a className="group flex items-center">
										<svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 mr-1 mb-[3px] h-4 w-4">
											<use xlinkHref="/icons.svg#icon-history"></use>
										</svg>
										<span className="group-hover:text-accent font-display dark:text-jacarta-200 text-sm font-semibold">
											View Details
										</span>
									</a>
								</Link>
							</div>
						</div>
					</article>
				);
			})}
			</InfiniteScroll>:<div className='w-full flex justify-center py-4'>
					<p className='font-bold'>😵‍💫 No Raffles to display!</p>
				</div>}
			{nonSyncedDataList.map((x,index)=>(
			<EmptyCategoryItem key={index} id={index}/>))}
		</>
	);
};

export default RaffleCategoryItem;
