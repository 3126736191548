const club_category_filter = [
    {
      id: 'raffles',
      svg: "raffle",
      text: "Raffles",
      live: true
    },
    {
      id: 'auctions',
      svg: "auction",
      text: "Auctions",
      live: true
    },
    {
      id: 'direct',
      svg: "direct",
      text: "Direct",
      live: false
    },
    {
      id: 'lottery',
      svg: "lottery",
      text: "Lottery",
      live: false
    }
  ];
  
  export { club_category_filter };