import React from 'react'

export default function EmptyCategoryItem({id}) {
  return (
    <article key={id}>
    <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
        <figure className="relative">
                    <div className="animate-pulse bg-jacarta-200 w-full h-[285px] rounded-[0.625rem] object-cover cursor-pointer"
                    />
        </figure>
        <div className="animate-pulse bg-jacarta-200 h-10 w-24 mt-4 mb-4 rounded">
        </div>
        <div className="animate-pulse bg-jacarta-200 h-6 mt-2 rounded">
        </div>
        <div className="animate-pulse bg-jacarta-200 h-4 mt-1 rounded">
        </div>
    </div>
</article>
  )
}
