import { sanityClient } from '../sanity';


export const getRafflesAll = async (projectId, startLimit = 0, endLimit = 20) => {
    const todayTs = new Date().getTime()

    const rafflesquery = `*[_type=="raffles" && project._ref == $projectId] | order(raffleEndTime desc) [$startLimit..$endLimit] {..., collection -> {
		collectionName
	},
	"islive": islive && $todayTs < raffleEndTime,
	"timeLeft": raffleEndTime - $todayTs, 
	"refresh": false
	}`

    const raffles = await sanityClient.fetch(rafflesquery, {todayTs, projectId, startLimit, endLimit})

    return raffles
}

export const getRafflesBatch = async (projectId, showLiveOnly, startLimit = 0, endLimit = 7) => {
	const todayTs = new Date().getTime()

    console.log(projectId, showLiveOnly, startLimit, endLimit)
	const base = `*[_type=="raffles" && `
	const live = showLiveOnly ? `raffleEndTime > $todayTs && islive && ` : `( raffleEndTime <= $todayTs || !islive) &&`
	const restQueryLive = `project._ref == $projectId] | order((raffleEndTime - $todayTs) asc) [$startLimit..$endLimit] {..., 
	"collection": collection -> {
		collectionName
	},
	"islive": islive && $todayTs < raffleEndTime,
	"timeLeft": raffleEndTime - $todayTs, 
	"refresh": false
	}`
    const restQueryFinished = `project._ref == $projectId] | order((raffleEndTime - $todayTs) desc) [$startLimit..$endLimit] {..., 
        "collection": collection -> {
            collectionName
        },
        "islive": islive && $todayTs < raffleEndTime,
        "timeLeft": raffleEndTime - $todayTs, 
        "refresh": false
        }`

        const query = showLiveOnly ? base+live+restQueryLive : base+live+restQueryFinished

	const raffles = await sanityClient.fetch(query, {todayTs, projectId, startLimit, endLimit})

    return raffles
}

export const getAuctionsAll = async (projectId, startLimit = 0, endLimit = 20) => {
    const todayTs = new Date().getTime()

    const auctionsquery = `*[_type=="auctions" && project._ref == $projectId] | order(auctionEndTime desc) [$startLimit..$endLimit] {..., collection -> {
		collectionName
	},
	"islive": $todayTs < auctionEndTime,
	"timeLeft": auctionEndTime - $todayTs, 
	"refresh": false
	}`

    const auctions = await sanityClient.fetch(auctionsquery, {todayTs, projectId, startLimit, endLimit})

    return auctions
}


export const getAuctionsBatch = async (projectId, showLiveOnly, startLimit = 0, endLimit = 7) => {
	const todayTs = new Date().getTime()

    console.log(projectId, showLiveOnly, startLimit, endLimit)
	const base = `*[_type=="auctions" && `
	const live = showLiveOnly ? `auctionEndTime > $todayTs && ` : `auctionEndTime <= $todayTs &&`
	const restQueryLive = `project._ref == $projectId] | order((auctionEndTime - $todayTs) asc) [$startLimit..$endLimit] {..., 
	"collection": collection -> {
		collectionName
	},
	"islive": $todayTs < auctionEndTime,
	"timeLeft": auctionEndTime - $todayTs, 
	"refresh": false
	}`
    const restQueryFinished = `project._ref == $projectId] | order((auctionEndTime - $todayTs) desc) [$startLimit..$endLimit] {..., 
        "collection": collection -> {
            collectionName
        },
        "islive": $todayTs < auctionEndTime,
        "timeLeft": auctionEndTime - $todayTs, 
        "refresh": false
        }`

        const query = showLiveOnly ? base+live+restQueryLive : base+live+restQueryFinished

	const auctions = await sanityClient.fetch(query, {todayTs, projectId, startLimit, endLimit})

    console.log("GETTING AUCTIONS", auctions.length)
    return auctions
}


export const getSingleAuction = async (pageSlug) => {

    const todayTs = new Date().getTime()

    const query = `*[ _type == "auctions" && auctionSlug.current == $pageSlug][0]{
        ...,
        "collection": collection->{
              meId,
          collectionName,
          collectionImage
        },
        "islive": $todayTs < auctionEndTime,
        "timeLeft": auctionEndTime - $todayTs, 
        "refresh": false
      }`

    const auction = await sanityClient.fetch(query, { pageSlug, todayTs })

    return auction
}