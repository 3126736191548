import React, { useEffect, useState } from 'react';
import { tranding_category_filter } from '../../data/categories_data';
import RaffleCategoryItem from './RaffleCategoryItem';
import AuctionCategoryItem from './AuctionCategoryItem'
import { trendingCategoryData } from '../../data/categories_data';
import Tippy from '@tippyjs/react';
import Recently_added_dropdown from '../dropdown/recently_added_dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { updateTrendingCategoryItemData } from '../../redux/counterSlice';
import { club_category_filter } from '../../data/club_categories_data';
import Raffle_listing_dropdown from '../dropdown/Raffle_listing_dropdown';

const Trending_categories_items = () => {

	 const [filterVal, setFilterVal] = useState('raffles');


	 const { auctionLiveDataList, raffleLiveDataList } = useSelector((state) => state.counter);

	 const hasLive = {
		raffles: raffleLiveDataList.length > 0,
		auctions: auctionLiveDataList.length > 0,
	 }
	  


	const handleFilter = (category) => {
			setItemdata(data.filter((item) => item._type === category));
	};

	const sortText = [
		{
			id: 1,
			text: 'Recently Added',
		},
		{
			id: 2,
			text: 'Price: Low to High',
		},
		{
			id: 3,
			text: 'Price: high to low',
		},
		{
			id: 4,
			text: 'Expiring Soon',
		},
		{
			id: 5,
			text: 'Selling out Soon',
		},
	];


	return (
		<>
			{/* <!-- Filter --> */}
			<div className="mb-8 flex flex-wrap items-center justify-between">
			<ul className="flex flex-wrap items-center">
					{club_category_filter.map(({ id, svg, text, live }) => {
							return (
								<li className="relative my-1 mr-2.5" key={id}>
									<button
										onClick={() => {
											// handleFilter(id);
											 setFilterVal(id);
										}}
                                        disabled={!live}
                                        className="relative"
									>
										<div
											className={
												filterVal === id
													? 'dark:border-accent group border-accent font-display flex h-9 items-center rounded-lg border-2 px-4 text-sm font-semibold transition-colors text-accent capitalize'
													: live ? 'dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize'
                                                    : 'dark:border-jacarta-600 dark:bg-jacarta-900 border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors dark:text-white capitalize'
											}
										>
											<svg
												className={
													filterVal === id
														? 'icon mr-1 h-4 w-4 transition-colors fill-white'
														: 'icon fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white'
												}
											>
												<use xlinkHref={`/icons.svg#icon-${svg}`}></use>
											</svg>
											<span>{text}</span>
										</div>
									</button>
									{!live ? <span className='absolute -top-1 -right-1 text-red text-xs'>soon</span> : 
									(hasLive[filterVal] &&
									<div class="absolute -top-1 -right-1 flex h-3 w-3">
									<span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green opacity-75"></span>
									<span class="relative inline-flex rounded-full h-3 w-3 bg-green"></span>
									</div>)}
								</li>
							);
					})}
				</ul>
				{/* dropdown */}
				<Raffle_listing_dropdown data={sortText} dropdownFor="recently_added" type={filterVal}/>
			</div>

			{/* <!-- Grid --> */}
			{
				filterVal === "raffles" ? <RaffleCategoryItem/> : filterVal === "auctions" ? <AuctionCategoryItem /> : null
			}
		</>
	);
};

export default Trending_categories_items;
