import React, { useContext, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Likes from '../likes';
import Auctions_dropdown from '../dropdown/Auctions_dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { buyModalShow } from '../../redux/counterSlice';
import { urlFor } from '../../sanity';
import TokenData from '../../data/token_data';
import Countdown_timer from '../Countdown_timer';
import { ClubBaseContext } from '../../contexts/ClubBaseProvider';
import { useRouter } from 'next/router';
import EmptyCategoryItem from './emptyCategoryItem';
import { MetaplexContext } from '../../contexts/MetaplexProvider';
import { dataURLtoFile, getMetadataToken, routeCheckSoloApp, toDataURL } from '../../utils/generalUtils';
import Raffle_item_dropdown from '../dropdown/Raffle_item_dropdown';
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from 'react-query'
import { getAuctionsBatch } from '../../libs/sanityHelper';
import Loader from '../other/Loader';

const AuctionCategoryItem = () => {
	const {allAuctions} = useContext(ClubBaseContext)
	const { auctionLiveDataList, auctionFinishedDataList, filterType } = useSelector((state) => state.counter);
//console.log(sortedtrendingCategoryItemData)
	const [newAuctionDataList, setNewAuctionDataList] = useState(auctionLiveDataList)
	const [newAuctionDataListFinished, setNewAuctionDataListFinished] = useState(auctionFinishedDataList)
	const [parsedData, setParsedData] = useState(null)
	const [nonSyncedDataList, setNonSyncedDataList] = useState([])
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0)
	const [synced, setSynced] = useState(false)
	const dispatch = useDispatch();
	const router = useRouter()

	const { projectData } = useSelector((state) => state.counter);
	// useEffect(() => {
	// 	setParsedData(sortedtrendingCategoryItemData)
	// }, [sortedtrendingCategoryItemData])
	const getAuctions = async () => { 
		const isLiveAuctions = router.query.type === "finished" ? false : true
		console.log("ROUTE", router.query.type)
		const auctions = await getAuctionsBatch(projectData._id, isLiveAuctions, (8*(page)), (8*(page+1))-1)
		if(auctions.length > 0){
			const liveAuctions = auctions.filter(x => x.islive)
			const finishedAuctions = auctions.filter(x => !x.islive)
			setPage(page + 1)
			setNewAuctionDataList([...newAuctionDataList, ...liveAuctions])
			setNewAuctionDataListFinished([...newAuctionDataListFinished, ...finishedAuctions])
			if(auctions.length < 8){
				setHasMore(false)
			}
		}else{
			setHasMore(false)
		}
	}

	const { data, refetch } = useQuery("auctionsList", getAuctions, {
		refetchOnWindowFocus: false
	  });


	  useEffect(() => {
		refetch()
		setParsedData(null)
		setPage(0)
		setHasMore(true)
	  }, [router.query.type])
	  
	

	const getMoreItems = () => { 
		console.log("Getting more items...", projectData)
		refetch()
	 }
	 

	 useEffect(() => {
		if(auctionLiveDataList){
			setNewAuctionDataList(auctionLiveDataList)
		}
	  }, [auctionLiveDataList])
	  
 
	  useEffect(() => {
		 if(auctionFinishedDataList){
			setNewAuctionDataListFinished(auctionFinishedDataList)
		 }
	   }, [auctionFinishedDataList])

	   

	useMemo(() => {
		console.log("DATA", allAuctions, newAuctionDataList)
		if((allAuctions && (newAuctionDataList.length > 0 || newAuctionDataListFinished.length > 0))){
			let syncdDatalistLive = []
			let syncdDatalistfinished = []
			let nonSyncDatalist = []
			let onchainauctionslive = allAuctions.filter(x => x.ongoing)
			console.log(onchainauctionslive)
			for (let i = 0; i < newAuctionDataList?.length; i++) {
				
				const index = onchainauctionslive.findIndex(x => x.accountKey.toString() === newAuctionDataList[i].id)

				if(index > -1){
					console.log("sanity data found")
					if(!newAuctionDataList[i].refresh){
						syncdDatalistLive.push({
							...newAuctionDataList[i],
							refresh: true,
							onchainData: onchainauctionslive[index]
						})

						onchainauctionslive.splice(index, 1)
					}
				}
			}
			let onchainauctionsfinished = allAuctions.filter(x => x.endTs.toNumber() * 1000 <= new Date().getTime())
			for (let i = 0; i < newAuctionDataListFinished?.length; i++) {
				
				const index = onchainauctionsfinished.findIndex(x => x.accountKey.toString() === newAuctionDataListFinished[i].id)

				if(index > -1){
					console.log("sanity data found")
					if(!newAuctionDataListFinished[i].refresh){
						syncdDatalistfinished.push({
							...newAuctionDataListFinished[i],
							refresh: true,
							onchainData: onchainauctionsfinished[index]
						})

						onchainauctionsfinished.splice(index, 1)
					}
				}
			}

			console.log("syncdDatalist",syncdDatalistLive, syncdDatalistfinished)
			setParsedData([...syncdDatalistLive, ...syncdDatalistfinished])

				setNonSyncedDataList([])
		}else{
			console.log("Else condition")
			setParsedData(null)
		}
	}, [allAuctions, newAuctionDataList, newAuctionDataListFinished])
	

	 const goToAuction = (slug) => { 
		router.push(routeCheckSoloApp(router.query.projectId, `auctions/${slug}`))
	 }


	 const chainBadge = (isonchain) => (
		<Tippy className='p-2' content={isonchain ? "On-Chain Reward":"Off-Chain Item"}>
		<div className='btn btn-circle btn-sm text-primary'>
		<svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 h-4 w-4">
											<use xlinkHref={`/icons.svg#icon-${isonchain ? "onchain":"offchain"}`}></use>
										</svg>
										</div></Tippy>
	 )

	 const typeBadge = (type) => (
		<Tippy className='p-2' content={type == "nft" ? "NFT item": type == "nft" ? "SFT/WL Token" : "Physical Item"}>
		<div className='btn btn-circle btn-sm text-accent'>
		<svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 h-4 w-4">
											<use xlinkHref={`/icons.svg#icon-${type}`}></use>
										</svg>
										</div></Tippy>
	 )


	return (
		<>
			{parsedData ? <InfiniteScroll
			className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4"
        dataLength={parsedData.length}
        next={getMoreItems}
        hasMore={hasMore}
        loader={<div className='w-full flex items-center justify-start px-4'><Loader/><p className='text-lg'>Loading more...</p></div>}
        endMessage={<div className='w-full flex items-center justify-start px-4'><p className='text-lg'>🥳 You are all caught up!</p></div>}
      >
			{parsedData?.map((item) => {
				const { refresh, _id, islive, auctionType, itemImage, itemName, startingPrice, tokenKey, auctionSlug, collection, timeLeft, isOnChain, onchainData } = item;
				const imageLink = urlFor(itemImage).url()
				const token = TokenData.find(x => x.mintKey === tokenKey)
				return (
					<article key={_id}>
						<div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
							
							<figure className={`relative w-full h-[285px] rounded-[0.625rem] object-cover cursor-pointer overflow-hidden`}>
										<Image 
											onClick={()=>goToAuction(auctionSlug.current)}
											src={imageLink}
											layout="fill"
											objectFit='cover'
											className={`${(!islive || onchainData?.live === 0) && "grayscale"} `}
										/>
								<Countdown_timer time={+timeLeft} type={"auction"}/>

								{/* <Likes like={likes} /> */}

								<div className="absolute right-3 top-3">
								<div className='flex items-center gap-1'>{chainBadge(isOnChain)}{typeBadge(auctionType)}</div>
								
								</div>
							</figure>
		
							<div className="relative mt-4 mb-4 flex items-center justify-between">
								<div className='flex flex-col items-start'>
								<Link href={routeCheckSoloApp(router.asPath, `auctions/${auctionSlug.current}`)}>
									<a>
										<span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
											{itemName}
										</span>
									</a>
								</Link>
								{collection ? <p className='flex items-center gap-1 text-xs text-accent-light'>
									<span>{collection.collectionName}</span> <svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 mr-1 mb-[3px] h-4 w-4">
											<use xlinkHref="/icons.svg#icon-shield"></use>
										</svg>
								</p>: <p className='flex items-center gap-1 text-xs text-accent-light'>
									<span>Unknown Collection</span>
								</p>}
								</div>
								{/* auction dropdown  */}
								<Raffle_item_dropdown classes="dark:hover:bg-jacarta-600 dropup hover:bg-jacarta-100 rounded-full" />
							</div>
								{/* <div className='text-xs'>
								<span className="dark:text-jacarta-200 text-jacarta-700 mr-1">Starting Price:</span>
								<span className="dark:text-jacarta-200 text-jacarta-700 mr-1">{startingPrice.toFixed(2)}</span>
								<span className="dark:text-jacarta-300 text-jacarta-500">
									{token.symbol}
								</span>
								</div> */}
								<div className='flex items-center gap-1 mb-3'>
								{!onchainData.firstBid && <svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-accent text-accent h-4 w-4">
                   					 <use xlinkHref="/icons.svg#icon-bolt"></use>
                  				</svg>} 
								<span className="dark:text-jacarta-200 text-jacarta-700 ">
									{onchainData.firstBid ? "Starting Price:"  : "Highest Bid:"}
									</span>
								{!refresh ? <div className='animate-pulse w-8 h-4 rounded bg-jacarta-100'></div> :
								<span className="dark:text-jacarta-200 text-accent font-bold">{(onchainData.price.toNumber()/token.lamportsPerToken).toFixed(2)}</span>}
								<span className="dark:text-jacarta-300 text-jacarta-500 font-bold">
									{token.symbol}
								</span>
								</div>
								{/* <div className='flex items-center gap-1'>
									{!refresh && <div className='animate-pulse w-8 h-4 rounded bg-jacarta-100'></div>} <span>{refresh && totalEntries} / {maxEntries}</span>
								</div> */}
{/* 
							{refresh ? <div className="w-full bg-jacarta-100 rounded-full h-2">
  <div className="bg-accent h-2 rounded-full" style={{width:`${parseInt((totalEntries/maxEntries)*100)}%`}}></div>
</div>
:
<div className="animate-pulse w-full bg-jacarta-100 rounded-full h-2"></div>} */}

							<div className="mt-2 flex items-center justify-between">
								<button
									className={`${islive ? "text-accent" : "text-jacarta-400"} font-display text-sm font-semibold`}
									onClick={() => dispatch(buyModalShow())}
								>
									{islive ? "LIVE" : "ENDED"}
								</button>
								<Link href={routeCheckSoloApp(router.asPath, `auctions/${auctionSlug.current}`)}>
									<a className="group flex items-center">
										<svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 mr-1 mb-[3px] h-4 w-4">
											<use xlinkHref="/icons.svg#icon-history"></use>
										</svg>
										<span className="group-hover:text-accent font-display dark:text-jacarta-200 text-sm font-semibold">
											View Details
										</span>
									</a>
								</Link>
							</div>
						</div>
					</article>
				);
			})}
			</InfiniteScroll>:<div className='w-full flex justify-center py-4'>
					<p className='font-bold'>😵‍💫 No Auctions to display!</p>
				</div>}
			{nonSyncedDataList.map((x,index)=>(
			<EmptyCategoryItem key={index} id={index}/>))}
		</>
	);
};

export default AuctionCategoryItem;
